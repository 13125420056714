import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { useLocation } from '@reach/router';
import SYSTEM_STRINGS from '../../utility/data/system/systemStrings.yaml';
import { Box } from '@mui/material';

/*---CVT---*/
import { CvtColorSchema, CvtBasicTheme } from '@conventic-web/internal.global';
import { BlockTop } from '@conventic-web/internal.cvt.layout.block-top';
import { BlockStandard } from '@conventic-web/internal.cvt.layout.block-standard';
import { HtmlContent } from '@conventic-web/internal.basic.html-content';
import { BulletList } from '@conventic-web/internal.cvt.data-display.bullet-list';
import { Hexagon } from '@conventic-web/internal.cvt.data-display.hexagon';

/*---Components---*/
import Layout from '../../components/skeleton_page/layout';
import Seo from '../../components/skeleton_page/seo';

/*---Objects---*/
import {
  PaddingObj,
  DiagonalObj,
  IconComponents,
} from '../../global/sharedObjects';

import MarkdownIt from 'markdown-it';
const md = MarkdownIt({ html: true });

const BeratungPage = ({ data }: { data: any }) => {
  const { frontmatter } = data.markdownRemark;
  const location = useLocation();
  const featuredImage = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.resize
    : null;

  const styles = {
    container__hexagons: { display: 'flex', flexWrap: 'wrap' },
    link__hexagons: {
      textDecoration: 'none',
      color: CvtColorSchema.fontDarkGray,
      margin: '1em 2em',
    },
    container: {
      subheadline: {
        textAlign: 'center',
        fontWeight: 'bold',
        paddingTop: '1em',
      },
    },
  };

  const hexagonList = [
    {
      link: SYSTEM_STRINGS.LINKS.INTERN.BERATUNG.STRATEGIE,
      graphic: IconComponents.CvtStrategieberatung,
      alt: SYSTEM_STRINGS.LINKS.NAME.BERATUNG.STRATEGIE,
      headline: SYSTEM_STRINGS.LINKS.NAME.BERATUNG.STRATEGIE,
    },
    {
      link: SYSTEM_STRINGS.LINKS.INTERN.BERATUNG.TECHNOLOGIE,
      graphic: IconComponents.CvtTechnologieberatung,
      alt: SYSTEM_STRINGS.LINKS.NAME.BERATUNG.TECHNOLOGIE,
      headline: SYSTEM_STRINGS.LINKS.NAME.BERATUNG.TECHNOLOGIE,
    },
    {
      link: SYSTEM_STRINGS.LINKS.INTERN.BERATUNG.MANAGEMENTBERATUNG,
      graphic: IconComponents.CvtManagementberatung,
      alt: SYSTEM_STRINGS.LINKS.NAME.BERATUNG.MANAGEMENTBERATUNG,
      headline: SYSTEM_STRINGS.LINKS.NAME.BERATUNG.MANAGEMENTBERATUNG,
    },
    {
      link: SYSTEM_STRINGS.LINKS.INTERN.BERATUNG.INTERIM_MANAGEMENT,
      graphic: IconComponents.CvtInterimManagement,
      alt: SYSTEM_STRINGS.LINKS.NAME.BERATUNG.INTERIM_MANAGEMENT,
      headline: SYSTEM_STRINGS.LINKS.NAME.BERATUNG.INTERIM_MANAGEMENT,
    },
    {
      link: SYSTEM_STRINGS.LINKS.INTERN.BERATUNG.PROJEKTBEGLEITUNG,
      graphic: IconComponents.CvtProjektbegleitung,
      alt: SYSTEM_STRINGS.LINKS.NAME.BERATUNG.PROJEKTBEGLEITUNG,
      headline: SYSTEM_STRINGS.LINKS.NAME.BERATUNG.PROJEKTBEGLEITUNG,
    },
    {
      link: SYSTEM_STRINGS.LINKS.INTERN.BERATUNG.SPECIAL_TOPICS,
      graphic: IconComponents.CvtSpecialTopics,
      alt: SYSTEM_STRINGS.LINKS.NAME.BERATUNG.SPECIAL_TOPICS,
      headline: SYSTEM_STRINGS.LINKS.NAME.BERATUNG.SPECIAL_TOPICS,
    },
  ];

  const createHexagonLinks = (hexagonList: any) => {
    return hexagonList.map((element: any, index: number) => {
      return (
        <React.Fragment key={`hexagon-links${index}`}>
          <Link to={element.link} style={styles.link__hexagons}>
            <Hexagon
              componentIcon={element.graphic}
              styleComponentIcon={{
                color: CvtColorSchema.white,
                fontSize: 80,
              }}
              size={6}
              alt={element.alt}
              clickable={true}
              invert={false}
              rotate={false}
              margin={'30%'}
            />
            <Box component="div" sx={styles.container.subheadline}>
              {element.headline}
            </Box>
          </Link>
        </React.Fragment>
      );
    });
  };

  return (
    <Layout>
      <Seo
        title={frontmatter.title}
        keywords={frontmatter.seoKeywords}
        description={frontmatter.pageDescription}
        image={featuredImage}
        pathname={location.pathname}
      />
      <BlockTop
        headline={frontmatter.title}
        subheadline={frontmatter.subtitle}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        bgImage={
          !frontmatter.image.childImageSharp &&
          frontmatter.image.extension === 'svg'
            ? frontmatter.image.publicURL
            : null
        }
        zIndex={8}
        padding={PaddingObj.PaddingStarter}
        color={CvtColorSchema.fontDarkGray}
      />
      <BlockStandard
        bgColor={CvtColorSchema.white}
        diagonalAlignment={DiagonalObj.DiagonalLeftUp as any}
        zIndex={7}
        padding={PaddingObj.PaddingIdle}
        color={CvtColorSchema.fontDarkGray}
        subheadline={frontmatter.consultingMain.headline}
      >
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.consultingMain.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
      </BlockStandard>
      <BlockStandard
        bgColor={CvtColorSchema.bgDarkGray}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        zIndex={6}
        padding={PaddingObj.PaddingIdle}
        color={CvtColorSchema.white}
        subheadline={frontmatter.consultingServices.headline}
      >
        <BulletList list={frontmatter.consultingServices.list} />
      </BlockStandard>

      <BlockStandard
        bgColor={CvtColorSchema.white}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        zIndex={5}
        padding={PaddingObj.PaddingIdle}
        color={CvtColorSchema.white}
        subheadline={frontmatter.consultingServices.headline}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          {createHexagonLinks(hexagonList)}
        </div>
      </BlockStandard>
    </Layout>
  );
};

BeratungPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BeratungPage;

export const beratungPageQuery = graphql`
  query BeratungPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        consultingServices {
          headline
          list {
            headline
            text
          }
        }
        consultingMain {
          content
          headline
        }
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
          extension
          publicURL
        }
        seoKeywords
        pageDescription
        featuredImage {
          childImageSharp {
            resize(width: 900) {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`;
